import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Vi kikar på det senaste från Nextcloud och får lite härlig statistik från IBM. Terraria siktar återigen på Stadia och Firefox släpper nytt. `}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Nextcloud 21: `}<a parentName="li" {...{
          "href": "https://nextcloud.com/blog/nextcloud-hub-21-out-with-up-to-10x-better-performance-whiteboard-and-more-collaboration-features/"
        }}>{`https://nextcloud.com/blog/nextcloud-hub-21-out-with-up-to-10x-better-performance-whiteboard-and-more-collaboration-features/`}</a></li>
      <li parentName="ul">{`IBM undersöker öppen källkod i molnet: `}<a parentName="li" {...{
          "href": "https://developer.ibm.com/blogs/oreilly-open-source-skill-survey-blog/"
        }}>{`https://developer.ibm.com/blogs/oreilly-open-source-skill-survey-blog/`}</a></li>
    </ul>
    <h3>{`Kort & Gott`}</h3>
    <ul>
      <li parentName="ul">{`Terraria (tillbaka) på Stadia:`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://forums.terraria.org/index.php?threads/terraria-state-of-the-game-february-2021.103428/"
            }}>{`https://forums.terraria.org/index.php?threads/terraria-state-of-the-game-february-2021.103428/`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.gamingonlinux.com/2021/02/terraria-for-google-stadia-is-surprisingly-back-on-and-going-through-certification"
            }}>{`https://www.gamingonlinux.com/2021/02/terraria-for-google-stadia-is-surprisingly-back-on-and-going-through-certification`}</a></li>
        </ul></li>
      <li parentName="ul">{`Firefox 86: `}<a parentName="li" {...{
          "href": "https://www.mozilla.org/en-US/firefox/86.0/releasenotes/"
        }}>{`https://www.mozilla.org/en-US/firefox/86.0/releasenotes/`}</a></li>
      <li parentName="ul">{`Firefox testar reklam`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://support.mozilla.org/en-US/kb/sponsor-privacy"
            }}>{`https://support.mozilla.org/en-US/kb/sponsor-privacy`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://old.reddit.com/r/linux/comments/lq4m4c/mozilla_is_testing_ads_sponsored_top_sites_in/"
            }}>{`https://old.reddit.com/r/linux/comments/lq4m4c/mozilla_is_testing_ads_sponsored_top_sites_in/`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`ytfzf: `}<a parentName="li" {...{
          "href": "https://github.com/pystardust/ytfzf"
        }}>{`https://github.com/pystardust/ytfzf`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <ul>
      <li parentName="ul">{`Gemini:`}<ul parentName="li">
          <li parentName="ul">{`gemini://gemini.trevligmjukvara.se`}</li>
        </ul></li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Det stavas Fuchsia, inte Fuschia (tack J O på tg)`}</li>
      <li parentName="ul">{`Nativefier (tack Shellback)`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`YouTube: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCRVmpkj-XM6UhUCjGiL3hhQ"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Matrix: `}<a parentName="li" {...{
          "href": "https://matrix.to/#/%23TrevligMjukvara:matrix.org"
        }}>{`#TrevligMjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://social.gelotte.dev/@seb"
        }}>{`@seb`}</a></li>
      <li parentName="ul">{`Seb på twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
      <li parentName="ul">{`Donationer: `}<a parentName="li" {...{
          "href": "https://liberapay.com/TrevligMjukvara/donate"
        }}>{`https://liberapay.com/TrevligMjukvara/donate`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Dreamy Flashback" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Backbay Lounge" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      